<template>
  <div>
    <div class="main">
		<!-- banner section -->
      <emb-home-main-banner> </emb-home-main-banner>
	  <!-- feature highlighter -->
      <emb-features :data="featuresData"> </emb-features>
      <emb-services-slides > </emb-services-slides>
      <emb-services-left-right > </emb-services-left-right>
<emb-highlight-visions> </emb-highlight-visions>
    </div>
  </div>
</template>
<script>
// layout components
import HomeMainBanner from "Components/Layouts/Banner/HomeMainBanner";

// widgets
import Features from "Components/Widgets/Feature";
import ServicesSlides from "Components/Widgets/ServicesSlides";
import ServicesLeftRight from "Components/Widgets/ServicesLeftRight";
import HighlightVisions from "Components/Widgets/HighlightVisions";

// data
import featuresData from "Assets/data/featuresData";

export default {
  components: {
    embHomeMainBanner: HomeMainBanner,
    embFeatures: Features,
    embServicesSlides: ServicesSlides,
    embServicesLeftRight: ServicesLeftRight,
    embHighlightVisions: HighlightVisions,
  },
  data() {
    return {
      featuresData,
    };
  },
  mounted() {
   
  },
  methods: {
    
  },
};
</script>
