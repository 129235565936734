<template>
  <div class="emb-service grey lighten-5 py-8">
    <div class="container">
      <v-row justify="space-between" align="center" no-gutters v-for="(service, key) in services" :key="key">
        <template v-if="key % 2">
          <v-col cols="6">
            <h2 class="">{{ service.title }}</h2>
            <h5 class="">{{ service.description }}</h5>
          </v-col>
          <v-col cols="5" class="feature-item pa-6 text-center">
            <img :src="service.image" class="img-fluid" />
          </v-col>
        </template>
        <template v-else>
          <v-col cols="5" class="feature-item pa-6 text-center">
            <img :src="service.image" class="img-fluid" />
          </v-col>
          <v-col cols="6">
            <h2 class="">{{ service.title }}</h2>
            <h5 class="">{{ service.description }}</h5>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Business Advisory",
          image: "/static/images/chainsense/advisory.png",
          description:
            "Assess the full potential of blockchain for your business. Get expert insights with plan development, stress testing, business process design, programming and launch.",
        },
        {
          title: "Strategy Consulting",
          image: "/static/images/chainsense/strategy.png",
          description:
            "Develop growth strategies with imperative advice that helps in implementing key blockchain strategies optimising business efficiency.",
        },
        {
          title: "Support & Maintenance",
          image: "/static/images/chainsense/marketing.png",
          description:
            "Support and maintenance from experts for crucial processes and product lines. We offer product support, maintenance engineering, and migration services, allowing you to concentrate your limited resources on new product development, client acquisition, and innovation.",
        },
        {
          title: "Marketing & Growth",
          image: "/static/images/chainsense/support.png",
          description:
            "The global blockchain market size is expected to grow from USD 3.0 billion in 2020 to USD 39.7 billion by 2025, at an impressive Compound Annual Growth Rate (CAGR) of 67.3% during 2020-2025. The increasing need for simplifying the business processes and the need for supply chain management applications integrated with blockchain technology will drive the overall blockchain market.",
        },
      ],
    };
  },
};
</script>
