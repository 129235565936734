<template>
  <div class="emb-services-wrap white py-8">
    <div class="container">
      <h2 class="font-weight-bold text-center mb-10">
        {{ $t("message.servingHeading") }}
      </h2>

      <v-row justify="center" align="center">
        <v-col cols="12" md="4">
          <img
            src="/static/images/chainsense/serving2.png"
            class="img-fluid service_glob"
            :style="rotateStyle"
          />
        </v-col>
        <v-col cols="12" md="7" class="feature-item pa-6 text-left">
          <slick ref="carousel" :options="slickOptions">
            <div
              v-for="(item, key) in consulting"
              :key="key"
              class="slide-item"
            >
              <h2>{{ item.title }}</h2>
              <h5>{{ item.descr }}</h5>
            </div>
          </slick>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  props: ["data"],
  components: { Slick },
  data() {
    return {
      angle: 0,
      rotateStyle: {},
      consulting: [
        {
          title: "Consulting",
          descr:
            "Explore the application and feasibility of blockchain technology in your business concept with our blockchain services.",
        },
        {
          title: "Service",
          descr:
            "Test your business idea to execution development enabled by blockchain experts, writers, and designers.",
        },
        {
          title: "Development",
          descr:
            "Get specific functionality developed for your needs. Our custom-fit solutions are backed by business analysis and seamless integration.",
        },
      ],
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: false,
        swipe: true,
        autoplay: true,
        pauseOnHover: true,
      },
    };
  },
  mounted() {
    setInterval(() => {
      this.rotateStyle = {
        transform: "rotate(" + Math.floor((this.angle += 120)) + "deg)",
        "transition-duration": "1s",
      };
    }, 3000);
  },
};
</script>
