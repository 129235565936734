<template>
  <div class="banner-section">
    <v-container class="py-15">
      <v-row justify="center" align="center">
        <v-col cols="10" class="text-center">
          <h2>We Solving Every Business Issue</h2>
          <p class="text-muted">With a custom consensus algorithm</p>
          <h4 class="mb-0">
            A comprehensive Blockchain solution catering to the
          </h4>
          <h4>core niche of each industry Globally !</h4>
          <img src="static/images/chain.png" width="250px" style="transform:rotate(-99deg)"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["data"],
  components: {},
  data() {
    return {};
  },
};
</script>
