<template>
	<div class="emb-feature-wrap  ">
		<div class="container">
			<h2 class="font-weight-bold white--text text-center mb-10">{{$t('message.ourCoverage')}}</h2>
			
			<v-row justify="center ">
					<v-col  class="feature-item pa-6 text-center" v-for="(feature,key) in data" :key="key">
						<img :src="feature.image" class="img-fluid"/>
						<h3 class="white--text">{{feature.value}}</h3>
						<h5 class="white--text">{{$t(feature.name)}}</h5>
					</v-col>
			</v-row>


		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
	}
</script>
<style >
	.feature-item img {max-height: 395px;}
</style>