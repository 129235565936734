<template>
  <div class="emb-highlight-wrap white py-8">
    <div class="container">
      <v-row justify="center" align="center">
        
        <v-col cols="12" class="feature-item pa-6 text-center">
          <slick ref="carousel" :options="slickOptions">
            <div
              class="slide-item"
            >
              <h2>Have an Idea? Let's Ideate!</h2>
            </div>
            <div
              class="slide-item"
            >
              <h2>Have an Idea? Let's Ideate!</h2>
            </div>
          </slick>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  props: ["data"],
  components: { Slick },
  data() {
    return {
      angle: 0,
      rotateStyle: {},
      
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: false,
        swipe: true,
        autoplay: true,
        pauseOnHover: true,
      },
    };
  },
  mounted() {
    setInterval(() => {
      this.rotateStyle = {
        transform: "rotate(" + Math.floor((this.angle += 120)) + "deg)",
        "transition-duration": "1s",
      };
    }, 3000);
  },
};
</script>
