export default [
    {
        image: "static/images/chainsense/swap.png",
        name:'message.blockchain',
        value:4
    },
    {
        image: "static/images/chainsense/exchanges.png",
        name:'Metaverse',
        value:1
    },
    {
        image: "static/images/chainsense/blockchain.png",
        name:'Cryptocity',
        value:2
    },
    {
        image: "static/images/chainsense/dexs.png",
        name:'dApps',
        value:50
    },
    {
        image: "static/images/chainsense/contracts.png",
        name:'Contracts',
        value:100
    }

]